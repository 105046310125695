import './App.css';
import PageContainer from './PageContainer/PageContainer';

function App() {
  return (
    <>
      <PageContainer />
    </>
  );
}

export default App;
